<template>
  <div>
    <app-print-preview doc-uri="cpar/print-preview"></app-print-preview>
  </div>
</template>

<script>
export default {
  
};
</script>
